function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      if (data[key] instanceof File) {
        if (parentKey)
          buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        else
          buildFormData(formData, data[key], key);
      }
      else
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    if (data !== null)
      formData.append(parentKey, data);
  }
}

function jsonToFormData(data) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

export default jsonToFormData